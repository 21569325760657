import React from "react";
import CoursePage from "../../components/pages/CoursePage/CoursePage";

const Course = () => {
  return (
    <div>
      <CoursePage />
    </div>
  );
};
export default Course;
