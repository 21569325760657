import React from 'react'
import { BottomNav } from '../BottomNav'
import { HistoryBannerMov } from './HistoryBannerMov'

export const HistoryMov = () => {
  return (
    <div>
        <HistoryBannerMov></HistoryBannerMov>
        <br></br>
        <br></br>
        <br></br>
        <BottomNav></BottomNav>
    </div>
  )
}
