import React from "react";
import Product from "../../components/pages/Shop/Product";

const Shop = () => {
  return (
    <>
      <Product />
    </>
  );
};

export default Shop;
