import React from "react";
import KaziPage from "../../components/pages/Kazi/KaziPage";

const Kazi = () => {
  return (
    <>
      <KaziPage />
    </>
  );
};
export default Kazi;
