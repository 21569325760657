import React from 'react';

const UserKaziServices = () => {
    return (
        <div>
            <h1>This is user kazi services</h1>
        </div>
    );
};

export default UserKaziServices;