import React from "react";
import FindAlawyerPage from "../../components/pages/FindAlawyer/FindAlawyerPage";

const FindAlawyer = () => {
  return (
    <div>
      <FindAlawyerPage />
    </div>
  );
};
export default FindAlawyer;
