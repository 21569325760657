import React from "react";
import AgentPage from "../../components/pages/Agent/AgentPage";

const Agent = () => {
  return (
    <>
      <AgentPage />
    </>
  );
};
export default Agent;
