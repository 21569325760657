import React from 'react';

const UserOngoingServices = () => {
    return (
        <div>
            <h1>This is user ongoing services</h1>
        </div>
    );
};

export default UserOngoingServices;