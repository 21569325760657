import ali from "./dummyImg/ali.png";
import meem from "./dummyImg/meem.png";
import sanjida from "./dummyImg/sanjida.png";
import shahriar from "./dummyImg/shahriar.png";
import shakil from "./dummyImg/shakil.png";
import tuhidul from "./dummyImg/tuhidul.png";
import tumpa from "./dummyImg/tumpa.png";

const dummyData = [
  {
    id: 1,
    firstName: "Sajia",
    lastName: "Jahan Rakha",
    img: sanjida,
    age: 22,
    hight: "5 feet 4",
    profession: "Student",
    education: " Hon's(Department of Marketing)",
    institution: "B.M college Barishal",
    father: " Md. Kamal Khan",
    mother: "Salma Begum",
    address: "Barishal Sahadar Barishal",
    gender: "f",
  },
  {
    id: 2,
    firstName: "Md. Sahriar",
    lastName: "Saiful",
    age: 26,
    hight: "5 feet 6",
    profession: "private holder",
    education: "Hon’s ( deparment of physics )",
    institution: "Dhaka college",
    father: "Md. Manik Khan",
    mother: "Anjira Begum",
    img: shahriar,
    gender: "m",
  },
  {
    id: 3,
    firstName: "Md. Shakil",
    lastName: "Mridha",
    age: 26,
    hight: "5 feet 5",
    profession: "private holder",
    education: "Hon’s ( deparment of management )",
    institution: "B.M college",
    father: "Md. Nasir Mridha",
    mother: "Shirin Akter",
    address: "barishal sadar, charaicha, kornokathi",
    img: shakil,
    gender: "m",
  },
  {
    id: 4,
    firstName: "Md. Tohidul",
    lastName: "Islam",
    age: 27,
    hight: "5 feet 6",
    profession: "Private holder",
    education: " Hon's(Department of History)",
    institution: "Dhaka college",
    father: "Md. Babul Mridha",
    mother: "Soniya begum",
    address: "Barishal shadar, Barishal",
    img: tuhidul,
    gender: "m",
  },
  {
    id: 5,
    firstName: "Tumpa",
    lastName: "Akter Sathi",
    age: 22,
    hight: "5 feet 4",
    profession: "Student",
    education: " Hon's(Department of Physics)",
    institution: "University of Barishal",
    father: "Md. Moshiur Rahman",
    mother: "Moyna Begum",
    address: "Patuakhali,  Sobuj bag.",
    img: tumpa,
    gender: "f",
  },
  {
    id: 6,
    firstName: "Sah Ali",
    lastName: "Islam",
    age: 26,
    hight: "5 feet 6",
    profession: "Private holder",
    education: " Hon's(Department of Bangla)",
    institution: "Barishal college",
    father: "Md. Manik Miya",
    mother: "Tara Begum",
    address: "Zatrabari Dhaka",
    img: ali,
    gender: "m",
  },
  {
    id: 7,
    firstName: "Nowrin",
    lastName: "Meem",
    age: 21,
    hight: "5 feet 5",
    profession: "Student",
    education: " Hon's(Department of Physics)",
    institution: "Barishal University",
    father: "Md. Badruzzaman",
    mother: "Runa jahan",
    address: "Chadpur, Matlab",
    img: meem,
    gender: "f",
  },
];

export default dummyData;
