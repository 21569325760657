import React from "react";

const TopGroom = () => {
  return (
    <div className="text-center py-[40px]">
      <a className="top-profile-btn" href="/">
        See More
      </a>
    </div>
  );
};

export default TopGroom;
