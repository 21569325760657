import React from 'react';

const UserAgentServices = () => {
    return (
        <div>
            <h1>This is user agent services</h1>
        </div>
    );
};

export default UserAgentServices;