import shop1 from "../../../assets/images/shop/01.jpg";
import shop2 from "../../../assets/images/shop/02.jpg";
import shop3 from "../../../assets/images/shop/03.jpg";
import shop4 from "../../../assets/images/shop/04.jpg";
import shop5 from "../../../assets/images/shop/05.jpg";
import shop6 from "../../../assets/images/shop/06.jpg";
import shop7 from "../../../assets/images/shop/07.jpg";
import shop8 from "../../../assets/images/shop/08.jpg";
import shop9 from "../../../assets/images/shop/09.jpg";
import shop10 from "../../../assets/images/shop/10.jpg";
import shop11 from "../../../assets/images/shop/11.jpg";
import shop12 from "../../../assets/images/shop/12 (1).jpg";

const shopData = [
    {
        title: "Butterfly Rings",
        image: shop1,
        id: 1,
    },
    {
        title: "Butterfly Rings",
        image: shop2,
        id: 2,
    },
    {
        title: "Butterfly Rings",
        image: shop3,
        id: 3,
    },
    {
        title: "Butterfly Rings",
        image: shop4,
        id: 4,
    },
    {
        title: "Butterfly Rings",
        image: shop5,
        id: 5,
    },
    {
        title: "Butterfly Rings",
        image: shop6,
        id: 6,
    },
    {
        title: "Butterfly Rings",
        image: shop7,
        id: 7,
    },
    {
        title: "Butterfly Rings",
        image: shop8,
        id: 8,
    },
    {
        title: "Butterfly Rings",
        image: shop9,
        id: 9,
    },
    {
        title: "Butterfly Rings",
        image: shop10,
        id: 10,
    },
    {
        title: "Butterfly Rings",
        image: shop11,
        id: 11,
    },
    {
        title: "Butterfly Rings",
        image: shop12,
        id: 12,
    },
];

export default shopData;
