import React from 'react'
import { PrivacyBanner } from './PrivacyBanner'

export const PrivacyMov = () => {
  return (
    <div>
        <PrivacyBanner></PrivacyBanner>
    </div>
  )
}
