import React from "react";

const TopProfessionKazi = () => {
  return (
    <>
      <div className="text-center py-[40px]">
        <a className="top-profile-btn" href="/">
          See More
        </a>
      </div>
    </>
  );
};

export default TopProfessionKazi;
